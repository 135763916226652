import { SnowplowService } from "~/services/public/Snowplow"
import {getv4LocaleFromI18nLocale} from "~/scripts/useful-functions";

export default ({ app }, inject) => {
  // Create the SnowplowService instance
  const snowplowService = new SnowplowService()
  const locale = getv4LocaleFromI18nLocale(app.i18n.locale)
  snowplowService.addLocaleContext(locale)

  // Inject it into the Nuxt context as $snowplow
  inject("snowplow", snowplowService)
}
