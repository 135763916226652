import { SET_INTERNAL_REVIEWS } from "./mutations"

export default {
  [SET_INTERNAL_REVIEWS]({ commit }, internalReviewData: PageInternalReviews) {
    const dataIsValid = Object.values(internalReviewData).every(value => value !== null)

    if (dataIsValid) {
      const nameList: string[] = internalReviewData.name.split(/,/g).map(str => str.trim())
      const locationList: string[] = internalReviewData.location.split(/,/g).map(str => str.trim())
      const ratingList: number[] = internalReviewData.rating.split(/,/g).map(parseFloat)
      const reviewList: string[] =
        internalReviewData.review
          .replace(/\\"/g, '"')
          .match(/"([^"])*"/g)
          ?.map(str => str.replace(/"/g, "").trim()) ?? []
      const typeList: string[] = internalReviewData.type.split(/,/g).map(str => str.trim())
      const dateList: string[] = internalReviewData.date.split(/,/g).map(str => str.trim())

      const reviews: InternalReview[] = reviewList.map((_, index) => {
        return {
          id: index,
          name: nameList[index],
          location: locationList[index],
          rating: ratingList[index],
          review: reviewList[index],
          type: typeList[index],
          typeEmoji: internalReviewData.typeEmoji[0],
          date: dateList[index]
        }
      })
      commit(SET_INTERNAL_REVIEWS, reviews)
    } else {
      commit(SET_INTERNAL_REVIEWS, [])
    }
  }
}
