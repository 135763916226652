import { _SET_LOCATION_INFO, _SET_MAP_LOCATIONS } from "./mutations"

export const SET_MAP_LOCATIONS = "setMapLocations"
export const SET_LOCATION_INFO = "setLocationInfo"

export default {
  [SET_MAP_LOCATIONS]({ commit }, locationData: PageLocation) {
    const latitudeList: number[] = locationData.mapPinsLatitude.split(/,/g).map(parseFloat)
    const longitudeList: number[] = locationData.mapPinsLongitude.split(/,/g).map(parseFloat)
    const textLine1List: string[] = locationData.mapPinsTextLine1.split(/,/g).map(str => str.trim())
    const textLine2List: string[] = locationData.mapPinsTextLine2.split(/,/g).map(str => str.trim())
    const textLine3List: string[] = locationData.mapPinsTextLine3.split(/,/g).map(str => str.trim())
    const starsList: string[] = locationData.mapPinsStars.split(/,/g).map(str => str.trim())
    const imageIds: string[] = (locationData.mapPinsImageIDs || "generic")
      .split(/,/g)
      .map(str => str.trim())

    const locations: MapLocation[] = latitudeList.map((value, index) => {
      return {
        id: index,
        lat: value,
        lng: longitudeList[index],
        textLine1: textLine1List[index],
        textLine2: textLine2List[index],
        textLine3: textLine3List[index],
        stars: starsList[index],
        imageID: imageIds[index]
      }
    })
    commit(_SET_MAP_LOCATIONS, locations)
  },
  [SET_LOCATION_INFO]({ commit }, locationData: PageLocation) {
    commit(_SET_LOCATION_INFO, {
      displayName: locationData.locationDisplayName,
      identifier: locationData.locationIdentifier,
      localExperts: locationData.summaryLocalExperts,
      savings: locationData.summarySavings,
      moves: locationData.summaryNeighboursMoved,
      summaryHeading: locationData.summaryHeading,
      summaryUsp1: locationData.summaryUsp1,
      summaryUsp1Emoji: locationData.summaryUsp1Emoji,
      summaryUsp2: locationData.summaryUsp2,
      summaryUsp2Emoji: locationData.summaryUsp2Emoji,
      summaryUsp3: locationData.summaryUsp3,
      summaryUsp3Emoji: locationData.summaryUsp3Emoji
    })
  }
}
